body {
  margin: 0;
  padding: 0;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}

.ant-layout-content {
  margin-top: 64px;
}

.space-between-list-items li {
  margin-bottom: 1rem;
}